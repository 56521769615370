<template>
  <div>
    <ManageOfficerDetails />
  </div>
</template>
<script>
import ManageOfficerDetails from "@/components/ManageOfficerDetails/ManageOfficerDetails";
export default {
  components: {
    ManageOfficerDetails,
  },
  created() {},
};
</script>
